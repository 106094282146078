export const WorkData = [
	{
		company: "Udchalo",
		designation: "Software Developer",
		dateJoining: "07-26-2021",
		dateEnd: "Present",
		companyLogo:
			"https://static.udchalo.com/client_assets/img/favicon/favicon-192x192.ico",
		work: "udChalo aims to make flights cost effective for Ex-Servicemen / Indian armed forces, Paramilitary, Veterans and Dependents.",
	},
];
